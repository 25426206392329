import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Photographer from "../../../assets/images/photographer-iconnnn.png"
import logoLight from "../../../assets/images/logo-light.png"


import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Media,
  Row,
  Table,
} from "reactstrap"
import Select from "react-select"

import { Button, Modal, ModalBody, ModalHeader, CardFooter } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import mini card widgets
import MiniCards from "./mini-card"

//Import Images
import profile1 from "assets/images/profile-img.png"

// import charts
import ApexRevenue from "../ApexRevenue"
import { getUserProfile } from "store/actions"
import images from "assets/images"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import projectColumns from "./projectColumns"
import cellEditFactory from "react-bootstrap-table2-editor"

const ContactsProfile = props => {
  const { userProfile, onGetUserProfile } = props
  // eslint-disable-next-line no-unused-vars
  const [modalcategory, setModalcategory] = useState(false)
  const [isProfileEdit, setIsProfileEdit] = useState(false)
  const [selectedSKilss, setSelectedSkilss] = useState([])
  const [CalBg, setCalBg] = useState(false)
  const [modal_standard, setmodal_standard] = useState(false)

  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" },
  ]

  const [miniCards, setMiniCards] = useState([
    {
      title: "Activated Sets",
      iconClass: "bx-check-circle",
      text: "125",
    },
    { title: "Total Cost", iconClass: "bx-package", text: "€36,524" },
    { title: "Today Active Set", iconClass: "bx-hourglass", text: "4" },
  ])

  useEffect(() => {
    onGetUserProfile()
  }, [onGetUserProfile])

  const toggleCategory = () => {
    console.log("a")
    setModalcategory(!modalcategory)
  }

  const handleValidEventSubmitcategory = (event, values) => {
    console.log("Aasasasasasasas")
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: "Confirm",
      start: new Date(),
      className: "bg-success"
        ? values.event_category + " text-white"
        : "bg-danger text-white",
    }
    // save new event

    dispatch(onAddNewEvent(newEvent))
    toggleCategory()
  }

  const handleDateClick = arg => {
    console.log(arg.dayEl.firstChild)
    var currentColor = arg.dayEl.firstChild.style.backgroundColor
    var color = currentColor === "green" ? "#f85252" : "green"
    console.log(arg.dayEl.firstChild.style.backgroundColor)
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }
    arg.dayEl.firstChild.style.backgroundColor = color
    // setSelectedDay(modifiedData)
    // toggleCategory()
    // toggleEventAdd()
  }

  const handleInputChange = value => {
    var arr = []
    // const inputValue = newValue.replace(/\W/g, '');
    console.log(value)
    value.map(key => {
      console.log(key.label)
      arr.push(key.label)
    })
    console.log(arr)
    setSelectedSkilss(arr)
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
  }

  const cellEdit = cellEditFactory({
    mode: "click",
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company Profile </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Company Profile" />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row  >
                    <Col xs="7" >
                      <div className="text-primary p-3">
                        <h5 className="text-white">Welcome Back !</h5>
                        <p className="text-white">It will seem like simplified</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={logoLight }
                          alt=""
                          className=""
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate">
                      Wesual
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                      Company
                      </p>
                    </Col>

                    <Col sm={8}>
                      <div className="pt-4">
                        <Row>
                          <Col xs="6">
                            <h5 className="font-size-15">
                              {userProfile.projectCount}
                            </h5>
                            <p className="text-muted mb-0">Sets</p>
                          </Col>
                          <Col xs="6">
                            <h5 className="font-size-15">€36,524</h5>
                            <p className="text-muted mb-0">Costs</p>
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <Link
                            className="btn btn-primary  btn-sm"
                            onClick={() => tog_standard()}
                          >
                            Edit Profile
                            <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div className="profile-calendar">
                
              </div>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
              <div>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Last Activities</CardTitle>

                    <div className="table-responsive">
                      <Table className="align-middle mb-0">
                        <thead>
                          <tr className="text-center">
                            <th>#</th>
                            <th>Set Name</th>
                            <th>Date</th>
                            <th>Location</th>
                            <th>Duration</th>
                            <th>Cost</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center">
                            <th scope="row">1</th>
                            <td>Set 1</td>
                            <td>04/13/2021</td>
                            <td>Milan</td>
                            <td>1H 32m</td>
                            <td>€650</td>

                            <td>
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                              >
                               

                                <button
                                  type="button"
                                  className="btn btn-light btn-sm ms-2"
                                >
                                <span className="badge rounded-pill bg-warning me-1">i</span>
                                <i className="bx bx-slider "></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        

                          <tr className="text-center">
                            <th scope="row">2</th>
                            <td>Set 4</td>
                            <td>04/13/2021</td>
                            <td>Milan</td>
                            <td>1H 32m</td>
                            <td>€550</td>

                            <td>
                              <div
                                className=" btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                              >
                                

                                

                                <button
                                  type="button"
                                  className="btn btn-light btn-sm ms-2"
                                >
                                <i className="bx bx-slider"></i>
                                </button>
                              </div>
                            </td>
                          </tr>

                          <tr className="text-center">
                            <th scope="row">3</th>
                            <td>Set 1</td>
                            <td>04/13/2021</td>
                            <td>Milan</td>
                            <td>1H 32m</td>
                            <td>€870</td>

                            <td>
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                              >
                              

                              <button
                                type="button"
                                className="btn btn-light btn-sm ms-2"
                              >
                              <i className="bx bx-slider"></i>
                              </button>
                              </div>
                            </td>
                          </tr>

                          <tr className="text-center">
                            <th scope="row">4</th>
                            <td>Set 5</td>
                            <td>04/13/2021</td>
                            <td>Milan</td>
                            <td>1H 32m</td>
                            <td>€330</td>

                            <td>
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                              >
                             

                              <button
                                type="button"
                                className="btn btn-light btn-sm ms-2"
                              >
                              <span className="badge rounded-pill bg-warning me-1">i</span>
                              <i className="bx bx-slider"></i>
                              </button>
                              </div>
                            </td>
                          </tr>

                          <tr className="text-center">
                            <th scope="row">5</th>
                            <td>Set 2</td>
                            <td>04/13/2021</td>
                            <td>Milan</td>
                            <td>1H 32m</td>
                            <td>€760</td>

                            <td>
                              <div
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                              >
                               

                              <button
                                type="button"
                                className="btn btn-light btn-sm ms-2"
                              >
                              <i className="bx bx-slider"></i>
                              </button>

                              
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </div>

              <Card>
                <CardBody>
                  <div id="revenue-chart" className="row">
                    <div className="col">
                      <CardTitle className="mb-5">My Costs</CardTitle>
                      <ApexRevenue />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modalcategory}
        toggle={toggleCategory}
        // className={props.className}
      >
        <ModalHeader toggle={toggleCategory} tag="h4">
          Add a category
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidEventSubmitcategory}>
            <Row form>
              <Col className="col-12 mb-3">
                <AvField
                  name="title_category"
                  label="Event Name"
                  type="text"
                  errorMessage="Invalid name"
                  validate={{
                    required: { value: true },
                  }}
                />
              </Col>
              <Col className="col-12 mb-3">
                <AvField
                  type="select"
                  name="event_category"
                  label="Choose Category Color"
                  value={event ? event.event_category : "bg-primary"}
                >
                  <option value="bg-success">Set 1</option>
                  <option value="bg-info">Set 2</option>
                  <option value="bg-warning">Set 3</option>
                  <option value="bg-danger">Set 4</option>
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    // disabled={isDisabled}
                    type="button"
                    className="btn btn-light me-2"
                    onClick={toggleCategory}
                  >
                    Close
                  </button>
                  <button
                    // disabled={isDisabled}
                    type="submit"
                    className="btn btn-success save-event"
                  >
                    Next
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal_standard} toggle={tog_standard}>
        <ModalHeader toggle={tog_standard} tag="h4">
          Edit Profile
        </ModalHeader>
        <ModalBody>
          <AvForm
          // onValidSubmit={handleValidUserSubmit}
          >
            <Row form>
              <Col xs={12}>
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid name"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    className="mb-3"
                    name="phone"
                    label="Phone"
                    type="text"
                    errorMessage="Invalid Phone"
                    validate={{
                      required: { value: true },
                    }}
                  />
                  <div className="mb-4">
                    <AvField
                      name="location"
                      label="Location"
                      type="text"
                      errorMessage="Invalid Location"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                  <label>Skills : </label>

                  {selectedSKilss.map((key, index) => {
                    return (
                      <span className="me-2 ms-2" key={key}>
                        {key}
                        {index === selectedSKilss.length - 1 ? "" : ","}
                      </span>
                    )
                  })}

                  <Select
                    classNamePrefix="select2-selection"
                    placeholder="Choose..."
                    title="Country"
                    options={options}
                    isMulti
                    onChange={value => handleInputChange(value)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                    onClick={tog_standard}
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
})

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile))
