import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
// import { Col, Container, Row, Modal, ModalHeader, ModalBody } from "reactstrap"
import { map } from "lodash"
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Media,
    Row,
    Table,
  } from "reactstrap"
import ApexRevenue from "../../Contacts/ApexRevenue"
import LatestTranaction from "../../Dashboard/LatestTranaction"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"
import MiniCards from "../../Contacts/ContactsProfile/mini-card"

const Payment = () => {
  const [miniCards, setMiniCards] = useState([
    {
      title: "Last Month",
      iconClass: "bx-check-circle",
      text: "€6,300",
    },
    { title: "Last Year", iconClass: "bx-package", text: "€36,524" },
    { title: "Active Set", iconClass: "bx-hourglass", text: "54" },
  ])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
        <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
          <div className="row">
          <Col >
          <Card>
          <CardBody>
          
            <div id="revenue-chart" className="row">
              <div className="col">
                <CardTitle className="mb-5">My Costs</CardTitle>
                <ApexRevenue />
              </div>
            </div>
          </CardBody>
        </Card>
        </Col>

        <Col lg="12">
        <LatestTranaction />
      </Col>

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Payment
