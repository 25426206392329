import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as NumericInput from "react-numeric-input"
import Editable from "react-bootstrap-editable"
import Slider from "react-slick"
import ReactPaginate from "react-paginate"

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  ButtonDropdown,
} from "reactstrap"
// import ReactNumeric from 'react-numeric';
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  CardFooter,
  Row,
} from "reactstrap"
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import Cookies from "js-cookie"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from "../../store/actions"

import DeleteModal from "./DeleteModal"
import MModels from "../../common/data/models"
import modelTags, { modelGender, modelAgency } from "common/data/model-tags"
// import modelGender from "../../common/data/model-tags"
//css
import "@fullcalendar/bootstrap/main.css"
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

//redux
import { useSelector, useDispatch } from "react-redux"

const Calender = props => {
  const settings = {
    className: "amiir",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 3,
    // slidesToScroll: 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    useCSS: true,
  }

  const myRefPhoto = useRef()
  const myRefStyle = useRef()
  const myRefDigi = useRef()
  const myRefModel = useRef()

  const dispatch = useDispatch()

  const { events, categories } = useSelector(state => ({
    events: state.calendar.events,
    categories: state.calendar.categories,
  }))

  const [modalEventEdit, setModalEventEdit] = useState(false)
  const [modalEvent, setmodalEvent] = useState(false)
  const [modalEventAdd, setmodalEventAdd] = useState(false)
  const [o, setO] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [modalcategory, setModalcategory] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [photoNum, setPhotoNum] = useState(0)
  const [singlebtn, setSinglebtn] = useState(false)
  const [styleNum, setStyleNum] = useState(0)
  const [isDisabled, setDisable] = useState(true)
  const [addSetDrop, setAddSetDrop] = useState("")
  const [digiNum, setDigiNum] = useState(0)
  const [modelCards, setModelCards] = useState(MModels)
  // const [tags, setTags] = useState([])
  // Models in modal state

  const [selectedModel, setSelectedModels] = useState([])
  // const [selectedModel, setSelectedModels] = useState([])
  const [modelName, setModelName] = useState("")
  const [modelSelectionBoxToggle, setModelSelectionBoxToggle] = useState(false)
  const [modelFromCheckBox, setModelFromCheckBox] = useState([])
  const [createSet, setCreateSet] = useState(false)
  const [setColor, setSetColor] = useState("bg-success")
  const [sets, setSets] = useState([
    {
      id: 1,
      title: "Set 1",
      type: "bg-success",
    },
    {
      id: 2,
      title: "Set 2",
      type: "bg-info",
    },
    {
      id: 3,
      title: "Set 3",
      type: "bg-warning",
    },
    {
      id: 4,
      title: "Set 4",
      type: "bg-danger",
    },
  ])

  const [createSetName, setCreateSetName] = useState("")

  // PAGINATIONNNNNN ./////////////////////////
  // const [offset, setOffset] = useState(0)
  // const [perPage, setPerPage] = useState(3)
  // const [currentPage, setCurrentPage] = useState()
  // const [pageCount, setPageCount] = useState()
  // const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0)
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(3)
  const [isModelUpdated, setIsModelUpdated] = useState(false)
  const [tempModelCard, setTempModelCard] = useState(MModels)

  useEffect(() => {
    dispatch(onGetCategories())
    dispatch(onGetEvents())
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  useEffect(() => {
    if (!modalEventEdit && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modalEventEdit, event, modalEvent])

  // useEffect(() => {
  //   var slice = []
  //   console.log(offset + perPage)
  //   slice = MModels.slice(offset, offset + perPage)
  //   setModelCards(slice)
  //   console.log(slice)
  // }, [currentPage])

  // useEffect(() => {
  //   console.log("updated")
  //   var arr = [...modelCards]
  //   // Fetch items from another resources.
  //   console.log("OFFSETTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT" ,offset)
  //   const endOffset = offset + itemsPerPage;
  //   console.log(`Loading items from ${offset} to ${endOffset}`);
  //   console.log((modelCards.slice(offset, endOffset)))
  //   arr = (modelCards.slice(offset, endOffset));

  //   setModelCards(arr)
  //   // console.log(Math.ceil(arr.length / itemsPerPage))
  //   // console.log(Math.ceil(modelCards.length / itemsPerPage))
  //   setPageCount(Math.ceil(modelCards.length / itemsPerPage));
  //   // console.log(modelCards)
  // }, [offset, itemsPerPage ]);

  //   useEffect(() => {
  // console.log("offset upodated" + offset)
  //   },[offset])

  useEffect(() => {
    var arr = [...tempModelCard]
    console.log(modelCards.length)
    console.log("base ofsettttttt" + itemOffset)
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage
    console.log(`Loading items from ${itemOffset} to ${endOffset}`)
    console.log(endOffset)
    arr = arr.slice(itemOffset, endOffset)
    console.log(arr)
    setModelCards(arr)
    var pgcount = Math.ceil(tempModelCard.length / itemsPerPage)
    setPageCount(pgcount)
    console.log(Math.ceil(modelCards.length / itemsPerPage))
  }, [itemOffset, isModelUpdated])

  /**
   * Handling the modal state
   */
  const toggleEdit = () => {
    setModalEventEdit(!modalEventEdit)
  }

  const toggleEventModal = () => {
    setmodalEvent(!modalEvent)
  }

  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }

  const toggleEventAdd = () => {
    setmodalEventAdd(!modalEventAdd)
  }

  const addModelHandler = () => {
    const modelss = [...selectedModel]

    modelss.push("Not set yet")
    console.log(modelss)
    setSelectedModels(modelss)
    setModelSelectionBoxToggle(true)
  }
  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }

    setSelectedDay(modifiedData)
    toggleCategory()
    // toggleEventAdd()
  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event

    setEvent({
      id: event.id,
      title: event.title,
      title_category: event.title_category,
      start: event.start,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
    })

    setIsEdit(true)
    toggleEventModal()
  }

  const editModalOpener = () => {
    toggleEdit()
    setmodalEvent(!modalEvent)
  }

  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (e, values) => {
    const date = event["start"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    if (isEdit) {
      const updateEvent = {
        id: event.id,
        title: values.title,
        classNames: values.category + " text-white",
        start: modifiedDate,
      }
      // update event
      dispatch(onUpdateEvent(updateEvent))
    } else {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values["title"],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.category + " text-white",
      }
      // save new event
      dispatch(onAddNewEvent(newEvent))
    }
    setSelectedDay(null)
    toggle()
  }

  const handleValidEventSubmitcategory = (event, values) => {
    console.log("Aasasasasasasas")
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: values["title_category"],
      start: selectedDay ? selectedDay.date : new Date(),
      className: values.event_category
        ? values.event_category + " text-white"
        : "bg-danger text-white",
    }
    // save new event

    dispatch(onAddNewEvent(newEvent))
    toggleCategory()
  }

  const addNewEventonCalendar = (event, values) => {
    // const newEvent = {
    //   id: Math.floor(Math.random() * 100),
    //   title: values["title_category"],
    //   start: selectedDay ? selectedDay.date : new Date(),
    //   className: values.event_category
    //     ? values.event_category + " text-white"
    //     : "bg-danger text-white",
    // };
    // // save new event
    // dispatch(onAddNewEvent(newEvent));
    // toggleCategory();
    // save new event
    // dispatch(onAddNewEvent(newEvent));
  }

  const modelSelectionHandler = event => {
    let modelsss = [...modelFromCheckBox]
    const isChecked = event.target.checked
    if (isChecked) {
      console.log(event.target.value)
      modelsss.push(event.target.value)
      setModelFromCheckBox(modelsss)
    } else {
      const index = modelsss.indexOf(event.target.value)
      modelsss.splice(index, 1)
      setModelFromCheckBox(modelsss)
    }
    console.log(modelsss)
  }

  const addSelectedModelFromCheckbox = () => {
    let arr1 = [...selectedModel]
    arr1.pop()
    let arr2 = [...modelFromCheckBox]
    let arr3 = arr1.concat(arr2)
    setSelectedModels(arr3)
    setModelSelectionBoxToggle(false)
    arr2.splice(0, arr2.length)
    setModelFromCheckBox(arr2)
    setTempModelCard(MModels)
    setIsModelUpdated(!isModelUpdated)
  }

  const cancelSelectedModelFromCheckbox = () => {
    let arr2 = [...modelFromCheckBox]
    let arr1 = [...selectedModel]
    arr1.pop()
    setSelectedModels(arr1)

    arr2.splice(0, arr2.length)
    setModelFromCheckBox(arr2)
    setModelSelectionBoxToggle(false)
  }
  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event))
    setDeleteModal(false)
    toggleEdit()
  }

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const date = event["date"]
    console.log(date)
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = ""
    const currentMin = ""
    const currentSec = ""
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )

    const draggedEl = event.draggedEl
    console.log(draggedEl)
    const modifiedData = {
      // id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: modifiedDate,
      className: draggedEl.className,
    }
    dispatch(onAddNewEvent(modifiedData))
  }

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   console.log("Hiii")
  // }

  const addEventDropHandler = e => {
    setAddSetDrop(e.target.innerText)
    console.log(e.target.innerText)
  }
  const addPhotographer = () => {
    let n = photoNum
    for (var i = 0; i < n; i++) {
      var li = document.createElement("li")
      li.classList.add("mt-4")

      var text = document.createTextNode("Searching ...")
      li.appendChild(text)
      var btn = document.createElement("button")
      var textClose = document.createTextNode("Close")
      btn.appendChild(textClose)
      btn.classList.add("btn")
      btn.classList.add("btn-sm")
      btn.classList.add("btn-danger")
      btn.classList.add("float-end")
      btn.onclick = nextHandler
      var element = document.getElementById("photographer")
      element.appendChild(li)
      li.appendChild(btn)
    }
  }

  const addStylist = () => {
    let n = styleNum
    for (var i = 0; i < n; i++) {
      var li = document.createElement("li")
      li.classList.add("mt-4")

      var text = document.createTextNode("Searching ...")
      li.appendChild(text)
      var btn = document.createElement("button")
      var textClose = document.createTextNode("Close")
      btn.appendChild(textClose)
      btn.classList.add("btn")
      btn.classList.add("btn-sm")
      btn.classList.add("btn-danger")
      btn.classList.add("float-end")
      btn.onclick = nextHandlerStyle
      var element = document.getElementById("stylist")
      element.appendChild(li)
      li.appendChild(btn)
    }
  }

  const addDigi = () => {
    let n = digiNum
    for (var i = 0; i < n; i++) {
      var li = document.createElement("li")
      li.classList.add("mt-4")

      var text = document.createTextNode("Searching ...")
      li.appendChild(text)
      var btn = document.createElement("button")
      var textClose = document.createTextNode("Close")
      btn.appendChild(textClose)
      btn.classList.add("btn")
      btn.classList.add("btn-sm")
      btn.classList.add("btn-danger")
      btn.classList.add("float-end")
      btn.onclick = nextHandlerDigi
      var element = document.getElementById("digi")
      element.appendChild(li)
      li.appendChild(btn)
    }
  }

  // const photographersData = { name: "Behzad", name: "Amirhesam", name: "Ali" }
  const photographersData = [
    { name: "Behzad" },
    { name: "Ali" },
    { name: "Amirhesam" },
  ]

  const handleNumeric = e => {
    setPhotoNum(e.target.value)
  }

  const phoChangeHandler = e => {
    // console.log(e)
    setPhotoNum(e)
  }

  const styleChangeHandler = e => {
    // console.log(e)
    setStyleNum(e)
  }

  const digiChangeHandler = e => {
    // console.log(e)
    setDigiNum(e)
  }

  const nextHandler = el => {
    // e.parentNode
    // console.log(el.parentNode)
    // this.removeChild(this.parentNode)
    // el.parentNode.removeChild(el);
    // HTMLElement.prototype.remove = function() { this.parentNode.removeChild(this); return this; }
    // el.remove(this)
    console.log(myRefPhoto.current.lastChild)
    // myRef.current.lastChild.classList.add("d-none")
    myRefPhoto.current.lastChild.remove()
  }

  const nextHandlerStyle = el => {
    // e.parentNode
    // console.log(el.parentNode)
    // this.removeChild(this.parentNode)
    // el.parentNode.removeChild(el);
    // HTMLElement.prototype.remove = function() { this.parentNode.removeChild(this); return this; }
    // el.remove(this)
    console.log(myRefStyle.current.lastChild)
    // myRef.current.lastChild.classList.add("d-none")
    myRefStyle.current.lastChild.remove()
  }

  const nextHandlerModel = event => {
    let modelsss1 = [...selectedModel]
    console.log(modelsss1)
    const index = modelsss1.indexOf(event.target.id)
    console.log(index)
    modelsss1.splice(index, 1)
    console.log(modelsss1)
    setSelectedModels(modelsss1)
  }

  const nextHandlerDigi = el => {
    // e.parentNode
    // console.log(el.parentNode)
    // this.removeChild(this.parentNode)
    // el.parentNode.removeChild(el);
    // HTMLElement.prototype.remove = function() { this.parentNode.removeChild(this); return this; }
    // el.remove(this)
    console.log(myRefDigi.current.lastChild)
    // myRef.current.lastChild.classList.add("d-none")
    myRefDigi.current.lastChild.remove()
  }

  const nextAddEvnetFirstStep = () => {
    toggleEventAdd()
    toggleEdit()
  }

  const searchChangeHandler = e => {
    // console.log(MModels);
    // var arr = [];
    // MModels.map(x => arr.push(x.name.toLowerCase()))
    // console.log(arr)

    console.log(e.target.value.length)
    var searchInput = ""
    searchInput = e.target.value.toLowerCase()
    // searchInput.toLowerCase
    // const modelData = MModels
    console.log(tempModelCard.length)

    let filtered = []
    // let filter = "L"

    for (var i = 0; i < MModels.length; i++) {
      if (MModels[i].name.toLowerCase().includes(searchInput)) {
        // console.log(modelData[i])
        filtered.push(MModels[i])
        console.log(filtered)
      }
    }

    setTempModelCard(filtered)
    setModelCards(tempModelCard)
    // if (e.target.value.length > 2) {

    //   setTempModelCard(filtered)
    // }
    setIsModelUpdated(!isModelUpdated)
  }

  const tagClickHandler = tag => {
    console.log(tag.key)
    var filtered = []
    // let filter = "L"
    for (var i = 0; i < MModels.length; i++) {
      if (MModels[i].tags.includes(tag.key)) {
        console.log(MModels[i])
        filtered.push(MModels[i])
        // console.log(filtered)
      }
    }
    console.log(filtered)
    setTempModelCard(filtered)
    setIsModelUpdated(!isModelUpdated)
  }

  // const handlePageClick = () => {
  //   console.log("a")
  //   // const selectedPage = e.selected
  //   // const offsett = selectedPage * this.state.perPage
  //   // setCurrentPage(selectedPage)
  //   // setOffset(offsett)
  // }

  // const handlePageClick = (event) => {
  //   console.log("clicked")
  //   // console.log(modelCards)
  //   // console.log(event.selected)
  //   // console.log(itemsPerPage + " itemperpage")
  //   // console.log(modelCards.length + " modelCards.length")
  //   // console.log((itemsPerPage % modelCards.length))
  //   const newOffset = event.selected * itemsPerPage % modelCards.length;
  //   // console.log(newOffset)
  //   console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
  //   setOffset(newOffset);
  // };

  const handlePageClick = event => {
    console.log(event.selected)
    console.log(itemsPerPage)
    console.log(modelCards.length)
    console.log(itemsPerPage % modelCards.length)
    console.log((event.selected * itemsPerPage) % modelCards.length)

    const newOffset = event.selected * itemsPerPage
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    )
    setItemOffset(newOffset)
  }

  const toggleCreateSet = () => {
    setCreateSet(!createSet)
  }

  const createSetHandler = e => {
    var arr = [...sets]
    e.preventDefault()
    var data = { id: arr.length + 1, title: createSetName, type: setColor }
    arr.push(data)
    setSets(arr)
    toggleCreateSet()
    setSetColor("bg-success")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Calendar </title>
        </MetaTags>

        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Wesual" breadcrumbItem="Calendar" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                      <Button
                        color="primary"
                        className="font-16 btn-block"
                        onClick={toggleCreateSet}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Create New Set
                      </Button>

                      <div id="external-events" className="mt-3">
                        <p className="text-muted">
                          Drag and drop your event or click in the calendar
                        </p>
                        {sets &&
                          sets.map((sets, i) => (
                            <div
                              className={`${sets.type} external-event text-white p-1 mb-2`}
                              key={"cat-" + sets.id}
                              draggable
                              onDrag={event => onDrag(event, sets)}
                            >
                              <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                              {sets.title}
                            </div>
                          ))}
                      </div>
                    </Col>
                    <Col className="col-lg-9">
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay",
                        }}
                        events={events}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                      />

                      {/* New/Edit event modal */}
                      <Modal
                        isOpen={modalEventEdit}
                        className={props.className}
                      >
                        <ModalHeader toggle={toggleEdit} tag="h4">
                          Edit Event
                        </ModalHeader>
                        <ModalBody>
                          <div className="row mb-3">
                            <div className="form-group">
                              <div className="align-items-center d-flex justify-content-between">
                                <label htmlFor="">From</label>
                                <input
                                  className="form-control time-hour-input"
                                  type="time"
                                  defaultValue="13:45:00"
                                  id="example-time-input"
                                />
                                <label htmlFor="">To</label>
                                <input
                                  className="form-control time-hour-input"
                                  type="time"
                                  defaultValue="13:45:00"
                                  id="example-time-input"
                                />
                                <button
                                  type="button"
                                  disabled={!isDisabled}
                                  onClick={() => setDisable(false)}
                                  className="btn btn-secondary btn-sm"
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <form onSubmit={e => handleSubmit(e)}>
                            <div className="row mt-3">
                              <div className="form-group">
                                <div className="event-edit-box">
                                  <label htmlFor="">Photographer</label>
                                  <NumericInput
                                    mobile
                                    className="form-control  "
                                    min={0}
                                    value={photoNum}
                                    onChange={e => phoChangeHandler(e)}
                                  />
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={addPhotographer}
                                    type="button"
                                    disabled={isDisabled}
                                  >
                                    Add
                                  </button>
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                  <ol
                                    id="photographer"
                                    className="d-block w-100"
                                    ref={myRefPhoto}
                                  >
                                    <li>
                                      <div className="d-flex justify-content-between mt-3">
                                        <label htmlFor="">Mario Rossi</label>
                                        <label htmlFor="">€96</label>
                                        <div>
                                          <button
                                            disabled={isDisabled}
                                            className="btn btn-success btn-sm save-event me-2"
                                          >
                                            Confirm
                                          </button>
                                          <button
                                            className="btn btn-danger btn-sm save-event "
                                            onClick={el => nextHandler(el)}
                                            type="button"
                                            disabled={isDisabled}
                                          >
                                            Next
                                          </button>

                                          <button
                                            disabled={isDisabled}
                                            type="button"
                                            className="btn btn-light btn-sm ms-2"
                                          >
                                            x
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  </ol>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="form-group">
                                <div className="event-edit-box">
                                  <label htmlFor="">Stylist</label>
                                  <NumericInput
                                    mobile
                                    className="form-control  "
                                    min={0}
                                    value={styleNum}
                                    onChange={e => styleChangeHandler(e)}
                                  />
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={addStylist}
                                    type="button"
                                    disabled={isDisabled}
                                  >
                                    Add
                                  </button>
                                </div>

                                <div className="d-flex justify-content-between mt-3">
                                  <ol
                                    className="d-block w-100"
                                    id="stylist"
                                    ref={myRefStyle}
                                  ></ol>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="form-group">
                                <div className="event-edit-box">
                                  <label htmlFor="">Digi</label>
                                  <NumericInput
                                    mobile
                                    className="form-control  "
                                    min={0}
                                    value={digiNum}
                                    onChange={e => digiChangeHandler(e)}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    disabled={isDisabled}
                                    onClick={addDigi}
                                  >
                                    Add
                                  </button>
                                </div>
                                <ol
                                  className="d-block w-100"
                                  id="digi"
                                  ref={myRefDigi}
                                ></ol>
                                <hr />
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="form-group">
                                <div className="event-edit-box">
                                  <label htmlFor="">Model</label>

                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => addModelHandler()}
                                    type="button"
                                    disabled={isDisabled}
                                  >
                                    Add
                                  </button>
                                </div>
                                <hr />
                                <ol>
                                  {selectedModel.map((key, index) => {
                                    return (
                                      <li key={index} className="mt-3">
                                        <div className="d-flex justify-content-between mt-3">
                                          <label htmlFor="">{key}</label>
                                          <div className="align-items-center d-flex flex-row">
                                            <span className="">Pending</span>
                                            <button
                                              className="btn btn-light btn-sm ms-2 save-event float-end "
                                              onClick={
                                                cancelSelectedModelFromCheckbox
                                              }
                                              type="button"
                                              id={key}
                                              disabled={isDisabled}
                                            >
                                              x
                                            </button>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  })}
                                </ol>
                                <div className="row text-center mt-5 justify-content-center">
                                  {modelSelectionBoxToggle && (
                                    <>
                                      <div className="search-box me-2 mb-2">
                                        <div className="position-relative">
                                          <Input
                                            type="text"
                                            className="form-control border-0"
                                            placeholder="Search..."
                                            onChange={e =>
                                              searchChangeHandler(e)
                                            }
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>

                                      <div className="mb-2">
                                        <div className="quiz_card_title-tags justify-content-between">
                                          <div
                                            className="btn-group btn-group-sm"
                                            role="group"
                                            aria-label="Basic example"
                                          >
                                            {modelGender.map(key => {
                                              return (
                                                <button
                                                  key={key}
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={() =>
                                                    tagClickHandler({ key })
                                                  }
                                                >
                                                  {key}
                                                </button>
                                              )
                                            })}
                                          </div>
                                          <div
                                            className="btn-group btn-group-sm"
                                            role="group"
                                            aria-label="Basic example"
                                          >
                                            {modelTags.map(key => {
                                              return (
                                                <button
                                                  key={key}
                                                  type="button"
                                                  className="btn btn-outline-danger"
                                                  onClick={() =>
                                                    tagClickHandler({ key })
                                                  }
                                                >
                                                  {key}
                                                </button>
                                              )
                                            })}
                                          </div>

                                          <Dropdown
                                            isOpen={singlebtn}
                                            toggle={() =>
                                              setSinglebtn(!singlebtn)
                                            }
                                          >
                                            <DropdownToggle
                                              className="btn btn-secondary btn-sm"
                                              caret
                                            >
                                              Agency{" "}
                                              <i className="mdi mdi-chevron-down" />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              {modelAgency.map(key => {
                                                return (
                                                  <DropdownItem key={key}>
                                                    {key}
                                                  </DropdownItem>
                                                )
                                              })}
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>
                                      </div>

                                      {modelCards.map((key, index) => {
                                        return (
                                          <div
                                            className="col-4  d-flex"
                                            key={index}
                                          >
                                            <div className="quiz_card_area">
                                              <input
                                                className="quiz_checkbox"
                                                type="checkbox"
                                                id={index}
                                                value={key.name}
                                                onChange={e =>
                                                  modelSelectionHandler(e)
                                                }
                                              />
                                              <div className="single_quiz_card">
                                                <div className="quiz_card_content">
                                                  <div className="quiz_card_icon">
                                                    <img
                                                      src={key.image}
                                                      className="model-select-modal img-fluid"
                                                      alt=""
                                                    />
                                                  </div>

                                                  <div className="quiz_card_title mt-3 p-0 ">
                                                    <h3 className="text-white">{key.name}</h3>
                                                  </div>

                                                  <div className="quiz_card_title-tags">
                                                    {key.tags.map(keys => {
                                                      return (
                                                        <Link
                                                          to="#"
                                                          className="badge bg-primary font-size-11 m-1"
                                                          key={keys}
                                                        >
                                                          {keys}
                                                        </Link>
                                                      )
                                                    })}
                                                  </div>

                                                  <div>
                                                    <label>{key.price}</label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })}
                                      <ReactPaginate
                                        nextLabel="next >"
                                        onPageChange={event =>
                                          handlePageClick(event)
                                        }
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                      />
                                    </>
                                  )}
                                </div>
                                <div className="float-end">
                                  
                                  <button
                                    disabled={isDisabled}
                                    type="button"
                                    className={
                                      modelSelectionBoxToggle
                                        ? "btn btn-danger "
                                        : "d-none"
                                    }
                                    onClick={cancelSelectedModelFromCheckbox}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    disabled={isDisabled}
                                    type="button"
                                    className={
                                      modelSelectionBoxToggle
                                        ? "btn btn-success ms-2"
                                        : "d-none"
                                    }
                                    onClick={addSelectedModelFromCheckbox}
                                  >
                                    Add model(s)
                                  </button>
                                </div>
                              </div>
                            </div>
                            {modelSelectionBoxToggle && 
                              <React.Fragment>
                              <div>
                              <hr />
                              <label>Total: </label>
                              <label className="ms-2">€1700 </label>
                            </div>

                            <hr />
                            </React.Fragment>
                          }
                            

                            <div className="row mt-4 text-center">
                              <div className="text-end">
                                <button
                                  type="button"
                                  disabled={isDisabled}
                                  className="btn btn-danger me-2"
                                  onClick={() =>
                                    setModalEventEdit(!modalEventEdit)
                                  }
                                >
                                  Cancel
                                </button>

                                <button
                                  type="button"
                                  disabled={isDisabled}
                                  className="btn btn-success save-event"
                                  onClick={toggleEdit}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </form>
                        </ModalBody>
                      </Modal>

                      <Modal isOpen={modalEventAdd} className={props.className}>
                        <ModalHeader toggle={toggleEventAdd} tag="h4">
                          Add Event
                        </ModalHeader>
                        <ModalBody>
                          <div className="row mb-3">
                            <label>
                              Choose your set:{" "}
                              <span
                                style={{ fontSize: "16px" }}
                                className="text-white ms-3 "
                              >
                                {addSetDrop}
                              </span>
                            </label>
                            <Dropdown
                              isOpen={singlebtn}
                              toggle={() => setSinglebtn(!singlebtn)}
                            >
                              <DropdownToggle
                                className="btn btn-secondary mt-3"
                                caret
                              >
                                Sets <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>

                              <DropdownMenu>
                                <DropdownItem
                                  onClick={e => addEventDropHandler(e)}
                                >
                                  {" "}
                                  Set 1{" "}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={e => addEventDropHandler(e)}
                                >
                                  Set 2
                                </DropdownItem>
                                <DropdownItem
                                  onClick={e => addEventDropHandler(e)}
                                >
                                  Set 3
                                </DropdownItem>
                                <DropdownItem
                                  onClick={e => addEventDropHandler(e)}
                                >
                                  Set 4
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>

                            <form></form>
                          </div>

                          <div className="row mt-4 text-center">
                            <div className="text-end">
                              <button
                                type="button"
                                className="btn btn-danger me-2"
                                onClick={() =>
                                  setModalEventEdit(!modalEventEdit)
                                }
                              >
                                Cancel
                              </button>

                              <button
                                type="button"
                                className="btn btn-success save-event"
                                onClick={nextAddEvnetFirstStep}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>

                      <Modal isOpen={modalEvent} className={props.className}>
                        <ModalHeader toggle={toggleEventModal} tag="h4">
                          Event Info
                        </ModalHeader>
                        <ModalBody>
                          <div className="row">
                            <div className="form-group d-flex justify-content-between">
                              <label htmlFor="">
                                Date : <span>11/16/2021</span>{" "}
                              </label>
                              <label htmlFor="" className="ml-5">
                                Hour : <span>Not Set</span>{" "}
                              </label>
                            </div>
                          </div>
                          <form onSubmit={e => handleSubmit(e)}>
                            <div className="row mt-3">
                              <div className="form-group d-flex justify-content-between">
                                <label htmlFor="">Photographer</label>
                                <label htmlFor="">Not set</label>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="form-group d-flex justify-content-between">
                                <label htmlFor="">Stylist</label>
                                <label htmlFor="">Not set</label>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="form-group d-flex justify-content-between">
                                <label htmlFor="">Digi</label>
                                <label htmlFor="">Not set</label>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="form-group d-flex justify-content-between">
                                <label htmlFor="">Models</label>
                                <label htmlFor="">Not set</label>
                              </div>
                            </div>
                            <div className="row mt-4 text-center">
                              <div className="take-action">
                                <p>
                                  <i className="bx bx-info-circle me-2"></i>Take
                                  an Acion
                                </p>
                                <button
                                  type="button"
                                  className="btn btn-warning save-event mx-1"
                                  onClick={editModalOpener}
                                >
                                  Edit
                                </button>
                              </div>
                            </div>
                          </form>
                        </ModalBody>
                      </Modal>

                      <Modal
                        isOpen={createSet}
                        toggle={toggleCreateSet}
                        className={props.className}
                      >
                        <ModalHeader toggle={toggleCategory} tag="h4">
                          Add a set
                        </ModalHeader>
                        <ModalBody>
                          <AvForm onValidSubmit={e => createSetHandler(e)}>
                            <Row form>
                              <Col className="col-12 mb-3">
                                <label htmlFor="">Set Name</label>
                                <AvInput
                                  name="title_category"
                                  label="Set Name"
                                  type="text"
                                  errorMessage="Invalid name"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={e => {
                                    setCreateSetName(e.target.value)
                                  }}
                                />
                              </Col>

                              <Col className="col-12 mb-3">
                                <label htmlFor="">Location</label>
                                <AvInput
                                  name="title_category"
                                  label="Set Name"
                                  type="text"
                                  errorMessage="Invalid name"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={e => {
                                    setCreateSetName(e.target.value)
                                  }}
                                />
                              </Col>

                              <Col className="col-12 mb-3">
                                <label>Choose Set Color</label>
                                <AvInput
                                  type="select"
                                  name="event_category"
                                  label="Choose Set Color"
                                  onChange={e => setSetColor(e.target.value)}
                                >
                                  <option value="bg-success">Green</option>
                                  <option value="bg-info">Blue</option>
                                  <option value="bg-warning">Yellow</option>
                                  <option value="bg-danger">Red</option>
                                </AvInput>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    // disabled={isDisabled}
                                    type="button"
                                    className="btn btn-light me-2"
                                    onClick={toggleCreateSet}
                                  >
                                    Close
                                  </button>
                                  <button
                                    // disabled={isDisabled}
                                    type="submit"
                                    className="btn btn-success save-event"
                                  >
                                    Add
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Calender
