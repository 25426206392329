import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as NumericInput from "react-numeric-input"
import Editable from "react-bootstrap-editable"
import Slider from "react-slick"
import ReactPaginate from "react-paginate"

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  ButtonDropdown,
} from "reactstrap"
// import ReactNumeric from 'react-numeric';
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  CardFooter,
  Row,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import Cookies from "js-cookie"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from "../../store/actions"

import DeleteModal from "./DeleteModal"
import MModels from "../../common/data/models"
import modelTags from "common/data/model-tags"
//css
import "@fullcalendar/bootstrap/main.css"
// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

//redux
import { useSelector, useDispatch } from "react-redux"

const AgencyDashboard = props => {
 

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Agency Dashboard</title>
        </MetaTags>

        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Agency" breadcrumbItem="Dashboard" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                     
                    </Col>
                    <Col className="col-lg-9">
                   
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default AgencyDashboard
