import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  CardFooter,
  Row,
  Form,
  CardTitle,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

const AddNewModel = () => {
  const [selectedFiles, setselectedFiles] = useState([])
  const [saveImgesClicked, setsSaveImgesClicked] = useState(false)

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add new model</title>
        </MetaTags>

        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Agency" breadcrumbItem="Add new model" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="h4 mb-5">
                Model Information
              </CardTitle>

              <div className="mb-5">
              <button className="btn btn-success">Add Model</button>
            </div>
                </div>
               

                  <div className="row">
                    <div className="col-6">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            // defaultValue="Artisanal kale"
                            placeholder="Model Name"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Phone
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="tel"
                            // defaultValue="Artisanal kale"
                            placeholder="Phone Number"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="email"
                            defaultValue="bootstrap@example.com"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-tel-input"
                          className="col-md-2 col-form-label"
                        >
                          Telephone
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="tel"
                            defaultValue="1-(555)-555-5555"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Shoes
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            // defaultValue="Artisanal kale"
                            // placeholder="Model Name"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Shoes
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            // defaultValue="Artisanal kale"
                            // placeholder="Model Name"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Eyes
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            // defaultValue="Artisanal kale"
                            // placeholder="Model Name"
                          />
                        </div>
                      </Row>
                    </div>
                    <div className="col-6">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">Size</label>
                        <div className="col-md-8">
                          <select className="form-control">
                            <option>XS</option>
                            <option>S</option>
                            <option>M</option>
                            <option>L</option>
                            <option>Xl</option>
                            <option>XXL</option>
                          </select>
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Height
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            // defaultValue="Artisanal kale"
                            // placeholder="Model Name"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Bust
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            // defaultValue="Artisanal kale"
                            // placeholder="Model Name"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">Size</label>
                        <div className="col-md-8">
                          <select className="form-control">
                            <option>XS</option>
                            <option>S</option>
                            <option>M</option>
                            <option>L</option>
                            <option>Xl</option>
                            <option>XXL</option>
                          </select>
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Waist
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            // defaultValue="Artisanal kale"
                            // placeholder="Model Name"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Hips
                        </label>
                        <div className="col-md-8">
                          <input
                            className="form-control"
                            type="text"
                            // defaultValue="Artisanal kale"
                            // placeholder="Model Name"
                          />
                        </div>
                      </Row>
                    </div>

                    <div className="col">
                    <CardTitle className="mb-4">Images</CardTitle>

                    <Form>
                      {!saveImgesClicked && (
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className="dropzone"
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>
                                  Drop files here or click to upload.
                                </h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}

                      <div
                        className="dropzone-previews mt-3 row"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 col-3 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col className="text-end">
                                    <button
                                      // disabled={isDisabled}
                                      type="button"
                                      className="btn btn-light btn-sm ms-2"
                                    >
                                      x
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                    </Form>

                    <div className="text-center mt-4">
                      {!saveImgesClicked && (
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={() => setsSaveImgesClicked(true)}
                        >
                          Save Imges
                        </button>
                      )}

                      {saveImgesClicked && (
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={() => setsSaveImgesClicked(false)}
                        >
                          Add More
                        </button>
                      )}
                    </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddNewModel
