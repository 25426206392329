import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Modal,
  CardColumns,
  Container,
} from "reactstrap"
import Rating from "react-rating"
import RatingTooltip from "react-rating-tooltip"

import { Link } from "react-router-dom"
import Reviews from "../../Ecommerce/EcommerceProducts/Reviews"

import MModels from "../../../common/data/models"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import img1 from "../../../assets/images/small/img-1.jpg"

const AgencyModels = () => {
  const modelData = MModels
  const [modelss, setModelss] = useState(modelData)
  const [modal_standard, setmodal_standard] = useState(false)
  const [rate, setRate] = useState("")
  const tooltipContent = ["Rate 1", "Rate 2", "Rate 3", "Rate 4", "Rate 5"]
  const [modelImages, setModelImages] = useState([])
  const [modalTitleModelName , setmodalTitleModelName] =useState("")
  const starStyle = {
    height: '28px',
    
    paddingLeft: '2px',
    paddingRight: '2px',
    color: 'red',
    lineHeight: '28px',
    marginLeft: '5px',
    marginRight: '5px',
  }

  const starContainer ={
    'color' : "red"
  }
  const [comments, setComments] = useState([
    {
      id: 1,
      img: "avatar2",
      name: "Brian",
      description:
        "If several languages coalesce, the grammar of the resulting language.",
      date: "5 hrs ago",
    },
    {
      id: 2,
      img: "avatar4",
      name: "Denver",
      description:
        "To an English person, it will seem like simplified English, as a skeptical Cambridge",
      date: "07 Oct, 2019",
      childComment: [
        {
          id: 1,
          img: "avatar5",
          name: "Henry",
          description:
            "Their separate existence is a myth. For science, music, sport, etc.",
          date: "08 Oct, 2019",
        },
      ],
    },
    {
      id: 3,
      img: "Null",
      name: "Neal",
      description:
        "Everyone realizes why a new common language would be desirable.",
      date: "05 Oct, 2019",
    },
  ])

  const onAddComment = commentText => {}

  const filterDataFunction = filterValue => {
    let filtered = []
    // let filter = "L"
    for (var i = 0; i < modelData.length; i++) {
      if (modelData[i].tags.includes(filterValue)) {
        console.log(modelData[i])
        filtered.push(modelData[i])
        console.log(filtered)
      }
    }
    setModelss(filtered)
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
  }

  const modelImgtoModalHandler = e => {
    let arr =[]
    for (var i = 0; i < MModels.length; i++) {
      MModels[i].name === e.target.id ? arr = MModels[i].images : ""
    }
    console.log(e.target.id)
    console.log(arr)
    setModelImages(arr);
    setmodalTitleModelName(e.target.id)
    tog_standard()
  }

  return (
    <>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
        className="amir"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {modalTitleModelName}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-5">
              <h5>Gallery</h5>
              <div className="d-flex flex-column border border-1 align-items-center mt-3">
                {modelImages.map((key, index) => {
                  return (
                    <React.Fragment key={index}>
                      <img
                        src={key}
                        className="img-fluid img-gallery-modal my-2"
                      />
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
            <div className="col-7">
            <h5>Rating</h5>
              <div className="my-5">
                <RatingTooltip
                starContainer={starContainer}
                  max={5}
                  clearRating={false}
                  onChange={rate => {
                    setdef(rate)
                  }}
                  tooltipContent={tooltipContent}
                  ActiveComponent={
                    <i
                      className="mdi mdi-star text-primary"
                      style={starStyle}
                    />
                  }
                  InActiveComponent={
                    <i
                      className="mdi mdi-star-outline text-muted"
                      style={starStyle}
                    />
                  }
                />
              </div>
              <hr className="my-3"/>
              <h5>Comments</h5>
              <div className="mt-3">
                <div className="d-flex flex-row add-comment-section mt-4 mb-4">
                  <img
                    className="img-fluid img-responsive rounded-circle mr-2"
                    src="https://i.imgur.com/qdiP4DB.jpg"
                    width="38"
                  />
                  <input
                    type="text"
                    className="form-control mr-3"
                    placeholder="Add comment"
                  />
                  <button className="btn btn-primary" type="button">
                    Comment
                  </button>
                </div>
                <div className="commented-section mt-2">
                  <div className="d-flex flex-row align-items-center commented-user">
                    <h5 className="ms-2">Corey oates</h5>
                    <span className="dot mx-2 mb-1"></span>
                  </div>
                  <div className="comment-text-sm">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </span>
                  </div>
                  <div className="reply-section">
                    <div className="d-flex flex-row align-items-center voting-icons">
                      <h6 className=" mt-2">Reply</h6>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="commented-section mt-2">
                  <div className="d-flex flex-row align-items-center commented-user">
                    <h5 className="ms-2">Behzad Bani</h5>
                    <span className="dot mx-2 mb-1"></span>
                  </div>
                  <div className="comment-text-sm">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </span>
                  </div>
                  <div className="reply-section">
                    <div className="d-flex flex-row align-items-center voting-icons">
                      <h6 className=" mt-2">Reply</h6>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-3" />
              
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button type="button" className="btn btn-primary ">
            Save changes
          </button>
        </div>
      </Modal>

      <div className="page-content">
        <MetaTags>
          <title>Models</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Models" breadcrumbItem="Models" />

          <Row>
            <h2 className="me-3">Filters: </h2>
            <div className="d-flex flex-wrap gap-2 justify-content-start mb-4 flex-column">
            <div className="mt-4 d-flex flex-row align-items-center">
            <h5 className="me-3">Size:</h5>
            <button
              type="button"
              className="btn btn-primary w-xs mx-2 ms-5 "
              onClick={() => filterDataFunction("SM")}
            >
              SM
            </button>

            <button
              type="button"
              className="btn btn-primary w-xs  mx-2 "
              onClick={() => filterDataFunction("S")}
            >
              S
            </button>

            <button
              type="button"
              className="btn btn-primary w-xs  mx-2"
              onClick={() => filterDataFunction("M")}
            >
              M
            </button>

            <button
              type="button"
              className="btn btn-primary w-xs mx-2 "
              onClick={() => filterDataFunction("L")}
            >
              L
            </button>

            <button
              type="button"
              className="btn btn-primary w-xs  mx-2"
              onClick={() => filterDataFunction("XL")}
            >
              XL
            </button>

            <button
              type="button"
              className="btn btn-primary w-xs  mx-2"
              onClick={() => filterDataFunction("XXL")}
            >
              XXL
            </button>
            <button
              type="button"
              className="btn btn-primary w-xs mx-2 "
              onClick={() => filterDataFunction("XXXL")}
            >
              XXXL
            </button>
            </div>
          
              <div className="mt-4 d-flex flex-row align-items-center">
              <h5 className="me-3">Agency:</h5>
              <button
              type="button"
              className="btn btn-primary w-xs  mx-2 ms-3"
              onClick={() => filterDataFunction("Agency1")}
            >
              Agency1
            </button>

            <button
              type="button"
              className="btn btn-primary w-xs  mx-2"
              onClick={() => filterDataFunction("Agency")}
            >
              Agency
            </button>

              </div>
             <div className="my-3">
             <button
                type="button"
                className="btn btn-warning w-xs btn-sm "
                onClick={() => setModelss(MModels)}
              >
                Reset
              </button>
             </div>
              
            </div>

            {modelss.map((key, value) => {
              return (
                <React.Fragment key={key.name}>
                  <Col mg={6} xl={3}>
                    <Card>
                      <CardImg
                        top
                        className="img-fluid model-card-img"
                        src={key.image}
                        alt="Skote"
                        id={key.name}
                      />
                      <CardBody>
                        <CardTitle className="mt-0 text-center">
                          {key.name}
                        </CardTitle>
                        <CardText className="mt-0 text-center">
                          {key.tags.map(keys => {
                            return (
                              <Link
                                to="#"
                                className="badge bg-primary font-size-11 m-1"
                                key={keys}
                              >
                                {keys}
                              </Link>
                            )
                          })}
                        </CardText>
                        <Link
                          id={key.name}
                          onClick={e => modelImgtoModalHandler(e)}
                          to="#"
                          className="stretched-link"
                        ></Link>
                      </CardBody>
                    </Card>
                  </Col>
                </React.Fragment>
              )
            })}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default AgencyModels
