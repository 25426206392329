import React, { useEffect , useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Link, withRouter } from "react-router-dom"
import { Col, Container, Row ,Modal ,ModalHeader , ModalBody} from "reactstrap"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Card
import CardContact from "./card-contact"

//redux
import { useSelector, useDispatch } from "react-redux";

import { getUsers as onGetUsers } from "../../store/contacts/actions"

const ContactsGrid = props => {
  const [modal_standard, setmodal_standard] = useState(false);

  const dispatch = useDispatch()

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }))

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers())
    }
  }, [dispatch, users])

  
  function tog_standard() {
    setmodal_standard(!modal_standard);
  
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Grid </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Freelancers" />
          <div className="d-flex justify-content-end"><button className="btn btn-success btn-lg mb-4" onClick={tog_standard}>Invite</button></div>
          
          <Row>
            {map(users, (user, key) => (
              <CardContact user={user} key={"_user_" + key} />
            ))}
          </Row>

          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-hourglass bx-spin me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={modal_standard} toggle={tog_standard}>
      <ModalHeader toggle={tog_standard} tag="h4">
        Invitiaion
      </ModalHeader>
      <ModalBody>
        <AvForm
          // onValidSubmit={handleValidUserSubmit}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <AvField
                  name="name"
                  label="Name"
                  type="text"
                  errorMessage="Invalid name"
                  validate={{
                    required: { value: true },
                  }}
                 
                />
              </div>
              <div className="mb-3">
              
              </div>
              <div className="mb-3">
                <AvField
                  name="email"
                  label="Email"
                  type="email"
                  errorMessage="Invalid Email"
                  validate={{
                    required: { value: true },
                  }}
                  
                />
              </div>
              
             <div className="mb-3">
             <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
             </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-user"
                  onClick={tog_standard}
                >
                  Send
                </button>
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>

    </React.Fragment>
  )
}

export default withRouter(ContactsGrid)
