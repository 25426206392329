import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
// import { Container } from "reactstrap";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  Modal,
  CardColumns,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Rating from "react-rating"
import RatingTooltip from "react-rating-tooltip"
import Slidewithfade from "../../Ui/CarouselTypes/slidewithfade"
import { Link } from "react-router-dom"
import Reviews from "../../Ecommerce/EcommerceProducts/Reviews"

import MModels from "../../../common/data/models"
import modelTags, {
  modelGender,
  modelAgency,
} from "../../../common/data/model-tags"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import img1 from "../../../assets/images/small/img-1.jpg"

const models = () => {
  const modelData = MModels
  const [modelss, setModelss] = useState(modelData)
  const [modal_standard, setmodal_standard] = useState(false)
  const [rate, setRate] = useState("")
  const tooltipContent = ["Rate 1", "Rate 2", "Rate 3", "Rate 4", "Rate 5"]
  const [modelImages, setModelImages] = useState([])
  const [modalTitleModelName, setmodalTitleModelName] = useState("")
  const [singlebtn, setSinglebtn] = useState(false)
  const [arr, setArr] = useState([
    { Height: "179" },
    { Bust: "84" },
    { Waist: "62" },
    { Hips: "90" },
    { Shoes: "40" },
    { Hair: "Brown" },
    { Eyes: "Blue" },
  ])

  const starStyle = {
    height: "28px",

    paddingLeft: "2px",
    paddingRight: "2px",
    color: "red",
    lineHeight: "28px",
    marginLeft: "5px",
    marginRight: "5px",
  }

  const starContainer = {
    color: "red",
  }
  const [comments, setComments] = useState([
    {
      id: 1,
      img: "avatar2",
      name: "Brian",
      description:
        "If several languages coalesce, the grammar of the resulting language.",
      date: "5 hrs ago",
    },
    {
      id: 2,
      img: "avatar4",
      name: "Denver",
      description:
        "To an English person, it will seem like simplified English, as a skeptical Cambridge",
      date: "07 Oct, 2019",
      childComment: [
        {
          id: 1,
          img: "avatar5",
          name: "Henry",
          description:
            "Their separate existence is a myth. For science, music, sport, etc.",
          date: "08 Oct, 2019",
        },
      ],
    },
    {
      id: 3,
      img: "Null",
      name: "Neal",
      description:
        "Everyone realizes why a new common language would be desirable.",
      date: "05 Oct, 2019",
    },
  ])

  const onAddComment = commentText => {}

  const filterDataFunction = filterValue => {
    console.log(filterValue.key)
    let filtered = []
    // let filter = "L"
    for (var i = 0; i < modelData.length; i++) {
      if (modelData[i].tags.includes(filterValue.key)) {
        console.log(modelData[i])
        filtered.push(modelData[i])
        console.log(filtered)
      }
    }
    setModelss(filtered)
  }

  function tog_standard() {
    setmodal_standard(!modal_standard)
  }

  const modelImgtoModalHandler = e => {
    let arr = []
    for (var i = 0; i < MModels.length; i++) {
      MModels[i].name === e.target.id ? (arr = MModels[i].images) : ""
    }
    console.log(e.target.id)
    console.log(arr)
    setModelImages(arr)
    setmodalTitleModelName(e.target.id)
    tog_standard()
  }

  // const showingModelInfo = ()=>{
  //   var arr = MModels.map(key => key.info)
  //   console.log(arr[0])
  //   arr[0].map((key,index)=>{
  //     return(
  //       console.log(key)

  //     )
  //   })
  // }

  return (
    <>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard()
        }}
        className="amir"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Model Info
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-5">
              
              <div className="d-flex flex-column align-items-center mt-3">
              <Col lg={12}>
              <Card>
                <CardBody>
                
                
                <Slidewithfade />
                </CardBody>
              </Card>
            </Col>
              </div>
            </div>
            <div className="col-7">
              <h5>{modalTitleModelName}</h5>
           
              <div className="row mt-4">
              
                <div className="col-3">
                  <div className="my-0">
                    <label>Height:</label>
                    <label className="ms-2">157</label>
                  </div>
                </div>

                <div className="col-3">
                  <div className="my-0">
                    <label>Bust:</label>
                    <label className="ms-2">80</label>
                  </div>
                </div>

                <div className="col-3">
                  <div className="my-0">
                    <label>Waist:</label>
                    <label className="ms-2">60</label>
                  </div>
                </div>

                <div className="col-3">
                  <div className="my-0">
                    <label>Hips:</label>
                    <label className="ms-2">85</label>
                  </div>
                </div>

                <div className="col-3">
                  <div className="my-0">
                    <label>Shoes:</label>
                    <label className="ms-2">38.5</label>
                  </div>
                </div>

                <div className="col-3">
                  <div className="my-0">
                    <label>Hair:</label>
                    <label className="ms-2">Dark Brown</label>
                  </div>
                </div>

                <div className="col-3">
                  <div className="my-0">
                    <label>Eyes:</label>
                    <label className="ms-2">Brown</label>
                  </div>
                </div>

                
              </div>
                <hr/> 
              <div className="my-0 ocd">
              <h5 className="mt-2">Rating</h5>
                <RatingTooltip
                  starContainer={starContainer}
                  max={5}
                  clearRating={false}
                  onChange={rate => {
                    setdef(rate)
                  }}
                  tooltipContent={tooltipContent}
                  ActiveComponent={
                    <i
                      className="mdi mdi-star text-primary"
                      style={starStyle}
                    />
                  }
                  InActiveComponent={
                    <i
                      className="mdi mdi-star-outline text-muted"
                      style={starStyle}
                    />
                  }
                />
              </div>
              <hr className="my-3" />
              <h5>Internal Comments</h5>
              <div className="mt-3">
                <div className="d-flex flex-row add-comment-section mt-4 mb-4">
                  <img
                    className="img-fluid img-responsive rounded-circle mr-2"
                    src="https://i.imgur.com/qdiP4DB.jpg"
                    width="38"
                  />
                  <input
                    type="text"
                    className="form-control mr-3"
                    placeholder="Add comment"
                  />
                  <button className="btn btn-primary" type="button">
                    Comment
                  </button>
                </div>
                <div className="commented-section mt-2">
                  <div className="d-flex flex-row align-items-center commented-user">
                    <h5 className="ms-2">Corey oates</h5>
                    <span className="dot mx-2 mb-1"></span>
                  </div>
                  <div className="comment-text-sm">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </span>
                  </div>
                  <div className="reply-section">
                    <div className="d-flex flex-row align-items-center voting-icons">
                      <h6 className=" mt-2">Reply</h6>
                    </div>
                  </div>
                </div>
                <hr />

                <div className="commented-section mt-2">
                  <div className="d-flex flex-row align-items-center commented-user">
                    <h5 className="ms-2">Behzad Bani</h5>
                    <span className="dot mx-2 mb-1"></span>
                  </div>
                  <div className="comment-text-sm">
                    <span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </span>
                  </div>
                  <div className="reply-section">
                    <div className="d-flex flex-row align-items-center voting-icons">
                      <h6 className=" mt-2">Reply</h6>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-3" />
            </div>
          </div>
        </div>
        <div className="modal-footer d-block">
          <button type="button" className="btn btn-warning float-start ">
            Send Message to Agency
          </button>
          
          <button type="button" className="btn btn-primary float-end ">
            Save changes
          </button>
        </div>
      </Modal>

      <div className="page-content">
        <MetaTags>
          <title>Models</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Models" breadcrumbItem="Models" />

          <Row>
            {/*<h2 className="me-3 ">Filterss: </h2> */}
            <div className="d-flex flex-wrap gap-2 justify-content-start mb-4 flex-column">
              <div className="mt-4 d-flex flex-row align-items-center justify-content-between">
                <div
                  className="btn-group btn-group-sm"
                  role="group"
                  aria-label="Basic example"
                >
                  <h5 className="me-3 mb-0 align-self-center">Size:</h5>
                  {modelTags.map(key => {
                    return (
                      <button
                        key={key}
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => filterDataFunction({ key })}
                      >
                        {key}
                      </button>
                    )
                  })}
                </div>

                <div
                  className="btn-group btn-group-sm"
                  role="group"
                  aria-label="Basic example"
                >
                  <h5 className="me-3 mb-0 align-self-center">Gender:</h5>
                  {modelGender.map(key => {
                    return (
                      <button
                        key={key}
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => filterDataFunction({ key })}
                      >
                        {key}
                      </button>
                    )
                  })}
                </div>
                <div className="btn-group btn-group-sm">
                  <h5 className="me-3 mb-0 align-self-center">Agency:</h5>
                  <Dropdown
                    isOpen={singlebtn}
                    toggle={() => setSinglebtn(!singlebtn)}
                  >
                    <DropdownToggle className="btn btn-secondary btn-sm" caret>
                      Agency <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {modelAgency.map(key => {
                        return (
                          <DropdownItem
                            onClick={() => filterDataFunction({ key })}
                            key={key}
                          >
                            {key}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className="btn-group btn-group-sm">
                  <button
                    type="button"
                    className="btn btn-warning w-xs btn-sm "
                    onClick={() => setModelss(MModels)}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>

            <Row className="mt-3 mx-0">
              {modelss.map((key, value) => {
                return (
                  <React.Fragment key={key.name}>
                    <Col mg={6} xl={3} className="model-container">
                      <Card className="model-cardsss mx-auto">
                        <div>
                          <CardImg
                            top
                            className="img-fluid model-card-img"
                            src={key.image}
                            alt="Skote"
                            id={key.name}
                          />
                        </div>
                        <CardBody>
                          <CardTitle className="mt-0 text-center">
                            {key.name}
                          </CardTitle>
                          <CardText className="mt-0 text-center">
                            {key.tags.map(keys => {
                              return (
                                <Link
                                  to="#"
                                  className="badge bg-primary font-size-11 m-1"
                                  key={keys}
                                >
                                  {keys}
                                </Link>
                              )
                            })}
                          </CardText>
                          <Link
                            id={key.name}
                            onClick={e => modelImgtoModalHandler(e)}
                            to="#"
                            className="stretched-link"
                          ></Link>
                        </CardBody>
                      </Card>
                    </Col>
                  </React.Fragment>
                )
              })}
            </Row>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default models
