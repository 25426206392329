const users = [
  {
    id: 1,
    name: "David McHenry",
    designation: "Photographer",
    color: "primary",
    email: "david@skote.com",
    projects: "125",
    tags: ["Digi", "Photographer"],
  },
  {
    id: 2,
    img: "avatar2",
    name: "Frank Kirk",
    designation: "Photographer",
    email: "frank@skote.com",
    projects: "132",
    tags: ["Photographer", "Stylist"],
  },
  {
    id: 3,
    img: "avatar3",
    name: "Rafael Morales",
    designation: "Digi",
    email: "Rafael@skote.com",
    projects: "1112",
    tags: ["Digi", "Stylist", "Digi"],
  },
  {
    id: 4,
    name: "Mark Ellison",
    designation: "Capture One",
    color: "success",
    email: "mark@skote.com",
    projects: "121",
    tags: ["Stylist", "Photographer", "Capture One"],
  },
  {
    id: 5,
    img: "avatar4",
    name: "Minnie Walter",
    designation: "Photographer",
    email: "minnie@skote.com",
    projects: "145",
    tags: [ "Stylist", "Photographer"],
  },
  {
    id: 6,
    img: "avatar5",
    name: "Shirley Smith",
    designation: "Stylist",
    email: "shirley@skote.com",
    projects: "136",
    tags: ["Stylist"],
  },
  {
    id: 7,
    name: "John Santiago",
    designation: "Photographer",
    color: "info",
    email: "john@skote.com",
    projects: "125",
    tags: [ "Photographer" ,"Digi"],
  },
  {
    id: 8,
    img: "avatar7",
    name: "Colin Melton",
    designation: "Stylist",
    color: "",
    email: "colin@skote.com",
    projects: "136",
    tags: ["Photographer",  "Stylist"],
  },
  {
    id: 9,
    name: "David McHenry",
    designation: "Photographer",
    color: "primary",
    email: "david@skote.com",
    projects: "125",
    tags: ["Stylist", "Photographer"],
  },
  {
    id: 10,
    img: "avatar2",
    name: "Frank Kirk",
    designation: "Stylist",
    email: "frank@skote.com",
    projects: "132",
    tags: [" Make-up artist", "Stylist",],
  },
  {
    id: 11,
    img: "avatar3",
    name: "Rafael Morales",
    designation: "Capture One",
    email: "Rafael@skote.com",
    projects: "1112",
    tags: ["Digi", "Capture One",],
  },
  {
    id: 12,
    name: "Mark Ellison",
    designation: "Make-up artist",
    color: "success",
    email: "mark@skote.com",
    projects: "121",
    tags: ["Stylist","Make-up artist"],
  },
  {
    id: 13,
    img: "avatar4",
    name: "Minnie Walter",
    designation: "Photographer",
    email: "minnie@skote.com",
    projects: "145",
    tags: ["Photographer",],
  },
]
const userProfile = {
  id: 1,
  name: "Cynthia Price",
  designation: "UI/UX Designer",
  img: "avatar1",
  projectCount: 125,
  revenue: 1245,
  personalDetail:
    "Hi I'm Cynthia Price,has been the industry's standard dummy text To an English person, it will seem like simplified English, as a skeptical Cambridge.",
  phone: "(123) 123 1234",
  email: "cynthiaskote@gmail.com",
  location: "California, United States",
  experiences: [
    {
      id: 1,
      iconClass: "bx-server",
      link: "#",
      designation: "Back end Developer",
      timeDuration: "2016 - 19",
    },
    {
      id: 2,
      iconClass: "bx-code",
      link: "#",
      designation: "Front end Developer",
      timeDuration: "2013 - 16",
    },
    {
      id: 3,
      iconClass: "bx-edit",
      link: "#",
      designation: "UI /UX Designer",
      timeDuration: "2011 - 13",
    },
  ],
  projects: [
    {
      id: 1,
      client : "Behzad",
      name: "Rome - (Set 2)",
      startDate: "2 Sep, 2019",
      deadline: "1h 32m",
      budget: "$506",
    },
    {
      id: 2,
      client : "Amazon",
      name: "San siro - (Set 1)",
      startDate: "1 Sep, 2019",
      deadline: "2h",
      budget: "$94",
    },
    {
      id: 3,
      name: "Torino - (Set 4)",
      client : "Client",

      startDate: "21 Sep, 2019",
      deadline: "3h 48m",
      budget: "$156",
    },
    {
      id: 4,
      name: "Napoli - (Set 2)",
      startDate: "29 Sep, 2019",
      deadline: "1h",
      client : "Behzad",

      budget: "$122",
    },
    {
      id: 5,
      name: "Venis - (Set 2)",
      startDate: "05 Oct, 2019",
      deadline: "51m",
      budget: "$164",
      client : "Behzad",

    },
    {
      id: 6,
      name: "Milan - (Set 1)",
      startDate: "17 Oct, 2019",
      deadline: "4h 50m",
      budget: "$192",
      client : "Behzad",

    },
    {
      id: 7,
      name: "New York - (Set 4)",
      startDate: "04 Nov, 2019",
      deadline: "2h 11m",
      budget: "$94",
      client : "Behzad",

    },
  ],
}
export { users, userProfile }