import React, { useState , useEffect } from "react"
import PropTypes from 'prop-types'
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"

import megamenuImg from "../../assets/images/megamenu-img.png"
import logo from "../../assets/images/logo.svg"
import logoLight from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.png"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

//i18n
import { withTranslation } from "react-i18next"

const Header = props => {
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");


  const [menu, setMenu] = useState(false)
  const [isSearch, setSearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const toggleTopDrawer = () => {
    setPosition('right');
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false);
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  

  const showingHeaderMenus = () => {
      if(path[1] === "agency"){
        return(
          <React.Fragment>
          <li className="nav-item dropdown text-white mx-2">
          <h5>
            <Link
              className="nav-link  arrow-none text-white "
             
              to="/agency/dashboard"
            >
             
              {("Dashboard")} 
              
            </Link>
            </h5>
          </li>

          <li className="nav-item dropdown mx-2">
          <h5>
            <Link
              to="/agency/models"
             
              className="nav-link arrow-none text-white"
            >
              
              {props.t("Models")} 
            </Link>
            </h5>
          </li>

          <li className="nav-item dropdown mx-2">
          <h5>
            <Link
              to="/agency/chat"
             
              className="nav-link arrow-none text-white"
            >
              
              {props.t("Chat")} 
            </Link>
            </h5>
          </li>
          </React.Fragment>
        )
      } else if(path[1] === "freelancer"){
        return(
          <React.Fragment>
          <li className="nav-item dropdown text-white mx-2">
          <h5>
            <Link
              className="nav-link  arrow-none text-white "
             
              to="/freelancer/dashboard"
            >
             
              {("Dashboard")} 
              
            </Link>
            </h5>
          </li>

          <li className="nav-item dropdown mx-2">
          <h5>
            <Link
              to="/freelancer/gallery"
             
              className="nav-link arrow-none text-white"
            >
              
              {props.t("Gallery")} 
            </Link>
            </h5>
          </li>

          <li className="nav-item dropdown mx-2">
            <h5>
            <Link
              to="/freelancer/chat"
             
              className="nav-link  arrow-none text-white"
            >
              
              {props.t("Chat")} 
            </Link>
            </h5>
          </li>
          </React.Fragment>
        )
      } else if(path[1] === "company"){
        return(
          <React.Fragment>
          <li className="nav-item dropdown text-white mx-2">
          <h5>
            <Link
              className="nav-link  arrow-none text-white "
             
              to="/company/dashboard"
            >
             
              {("Dashboard")} 
              
            </Link>
            </h5>
          </li>

          <li className="nav-item dropdown text-white mx-2">
          <h5>
            <Link
              className="nav-link  arrow-none text-white "
             
              to="/company/calendar"
            >
             
              {("Calendar")} 
              
            </Link>
            </h5>
          </li>

          <li className="nav-item dropdown mx-2">
          <h5>
            <Link
              to="/company/models"
             
              className="nav-link arrow-none text-white"
            >
              
              {props.t("Models")} 
            </Link>
            </h5>
          </li>

          <li className="nav-item dropdown mx-2">
            <h5>
            <Link
              to="/company/freelancers"
             
              className="nav-link  arrow-none text-white"
            >
              
              {props.t("Freelancers")} 
            </Link>
            </h5>
          </li>

          <li className="nav-item dropdown mx-2">
          <h5>
            <Link
              to="/company/chat"
              className="nav-link arrow-none text-white"
             
            >
              
              {props.t("Chat")} 
            </Link>
            </h5>
          </li>
          </React.Fragment>
        )
      }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="19" />
                </span>
              </Link>
            </div>

            <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu mt-2"
            id="navigation"
          >
            
              <ul className="navbar-nav">
               
              {showingHeaderMenus()}
               
              </ul>
            
          </nav>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

         

            
          
          

          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            

            

          

            <NotificationDropdown />

            <ProfileMenu />

           
          </div>
        </div>
      </header>
      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
