const events = [
  
]

const calenderDefaultCategories = [
  {
    id: 1,
    title: "Set 1",
    type: "bg-success",
  },
  {
    id: 2,
    title: "Set 2",
    type: "bg-info",
  },
  {
    id: 3,
    title: "Set 3",
    type: "bg-warning",
  },
  {
    id: 4,
    title: "Set 4",
    type: "bg-danger",
  },
]

export { calenderDefaultCategories, events }
