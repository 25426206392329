import img1 from "../../assets/images/models/img1.jpg"
import img2 from "../../assets/images/models/img2.jpg"
import img3 from "../../assets/images/models/img3.jpg"
import img4 from "../../assets/images/models/img4.jpg"
import img5 from "../../assets/images/models/img5.jpg"
import img6 from "../../assets/images/models/img6.jpg"
import img7 from "../../assets/images/models/img7.jpg"
import img8 from "../../assets/images/models/img8.jpg"
import img9 from "../../assets/images/models/img9.png"


const models = [
  {
    name: "Ajsa",
    image: img1,
    price : "€300",
    tags: ["XL", "Agency"],
    images: [img4, img5],
    info: [
      { Height: "179" },
      { Bust: "84" },
      { Waist: "62" },
      { Hips: "90" },
      { Shoes: "40" },
      { Hair: "Brown" },
      { Eyes: "Blue" },
    ],
  },
  {
    name: "Agata",
    image: img8,
    price : "€400",
    tags: ["S", "Agency1"],
    images: [img1],
    info: [
      { Height: "179" },
      { Bust: "84" },
      { Waist: "62" },
      { Hips: "90" },
      { Shoes: "40" },
      { Hair: "Brown" },
      { Eyes: "Blue" },
    ],
  },
  {
    name: "Anna S",
    image: img3,
    price : "€150",
    tags: ["S", "Agency1"],
    images: [img1, img3, img4],
    info: [
      { Height: "179" },
      { Bust: "84" },
      { Waist: "62" },
      { Hips: "90" },
      { Shoes: "40" },
      { Hair: "Brown" },
      { Eyes: "Blue" },
    ],
  },
  {
    name: "Amelie",
    image: img4,
    price : "€20",
    tags: ["M", "Agency1"],
    images: [img1, img3, img4, img5],
    info: [
      { Height: "179" },
      { Bust: "84" },
      { Waist: "62" },
      { Hips: "90" },
      { Shoes: "40" },
      { Hair: "Brown" },
      { Eyes: "Blue" },
    ],
  },
  {
    name: "Aya B",
    image: img5,
    price : "€500",
    tags: ["Xxxl", "Agency1"],
    images: [img1, img3, img4, img5],
    info: [
      { Height: "179" },
      { Bust: "84" },
      { Waist: "62" },
      { Hips: "90" },
      { Shoes: "40" },
      { Hair: "Brown" },
      { Eyes: "Blue" },
    ],
  },
  {
    name: "Coral",
    image: img6,
    price : "€450",
    tags: ["XS", "Agency1"],
    images: [img1],
    info: [
      { Height: "179" },
      { Bust: "84" },
      { Waist: "62" },
      { Hips: "90" },
      { Shoes: "40" },
      { Hair: "Brown" },
      { Eyes: "Blue" },
    ],
  },
  {
    name: "Leo",
    image: img7,
    price : "€380",
    tags: ["S", "Agency1"],
    images: [img1, img3],
    info: [
      { Height: "179" },
      { Bust: "84" },
      { Waist: "62" },
      { Hips: "90" },
      { Shoes: "40" },
      { Hair: "Brown" },
      { Eyes: "Blue" },
    ],
  },
  {
    name: "Eden",
    image: img9,
    price : "€750",
    tags: ["XXL", "S", "Agency1"],
    info: [
      { Height: "179" },
      { Bust: "84" },
      { Waist: "62" },
      { Hips: "90" },
      { Shoes: "40" },
      { Hair: "Brown" },
      { Eyes: "Blue" },
    ],
  },
]

export default models
